import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import NewsletterForm from './newsletterForm';

const styles = css`
  .image {
    @media (max-width: 60rem) {
      display: none !important;
    }
    @media (min-width: 60rem) {
      position: absolute !important;
      right: 3%;
      top: 9.5%;
      box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 0, 0, 0.1) 0px 20px 30px;
      border-radius: 3px;
    }
  }

  .cta {
    color: #000;
    max-width: 60rem;
    margin: 6rem 1rem 6rem;
    position: relative;
    text-align: center;
    overflow: hidden;
    & form {
      margin: 0 auto;
    }

    @media (min-width: 60rem) {
      border-radius: 1rem;
      box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 0, 0, 0.1) 0px 20px 30px;
      color: #fff;
      text-align: left;
      background: #4ca2cd linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
      padding: 4rem 36rem 3rem 4rem;

      & .button {
        color: #fff;
      }

      & form {
        margin: 0;
      }
    }

    @media (min-width: 60rem) {
      margin-left: auto;
      margin-right: auto;
    }
    & h3 {
      line-height: 1.2;
      margin-bottom: 1rem;
      font-size: calc(20px + 0.5vw);
      font-weight: 600;
    }

    & p {
      margin-bottom: 2rem;
    }

    & .button {
      pointer-events: all;
      margin: 1rem 0;
      font-size: calc(16px + 0.4vw);
      border-bottom: 1px solid #000;
      line-height: 1.25;
      padding: 0.33em 0;
      display: inline-block;
      text-decoration: none;
      line-height: 26px;
      transition: all 0.25s ease-in-out;
      border-radius: 0;
      box-shadow: initial;
      font-weight: 800;

      @media (min-width: 600px) {
        font-size: 22px;
      }

      &::after {
        position: absolute;
        right: 0px;
        bottom: -1px;
        content: ' ';
        width: 100%;
        height: 1px;
        background: #fff;
        transition: 0.25s ease-out width;
      }

      & svg {
        transform: translateX(0);
        transition: 0.2s 0.12s ease-out;
      }

      &:hocus {
        color: #000;
        text-decoration: none;
        background: none;

        & svg {
          transform: translateX(4px);
        }

        &::after {
          width: 0;
        }
      }

      & svg {
        vertical-align: bottom;
      }
    }
  }
`;

export default () => (
  <div className={styles.cta}>
    <h3>Sign up for our newsletter</h3>
    <p>Don't miss any new announcements from Polypane and be the first to know when we launch new resources and tools.</p>
    <NewsletterForm />
    <StaticImage
      src="../../assets/images/overview-dark.png"
      width={480}
      className={styles.image}
      alt="Polypane screenshot"
      title="Polypane screenshot"
    />
  </div>
);
